import { navigate, useLocation } from "@reach/router";
import { useLocale } from "hooks/useLocale";
import React, { useEffect } from "react";
import styled from "styled-components";
import { DatoCmsZohoForm } from "templates/page";
import { computePath } from "utils/common/navigation";

interface Props {
  content: DatoCmsZohoForm;
}

const ZohoForm: React.SFC<Props> = ({ content }) => {
  const { locale, defaultLocale } = useLocale();
  const location = useLocation();

  const shouldInterceptSubmission = () => {
    return location.pathname.includes("private-november");
  };

  useEffect(() => {
    if (!shouldInterceptSubmission()) return;

    const interceptForm = () => {
      const iframe = document.querySelector("iframe");
      if (!iframe) return;

      const iframeDoc =
        iframe.contentDocument || iframe.contentWindow?.document;
      if (!iframeDoc) return;

      const form = iframeDoc.querySelector("form");
      if (!form) return;

      // Override the form submission
      form.addEventListener("submit", async (e) => {
        e.preventDefault();

        // Get reference to the validation function from the iframe
        const checkMandatory = (iframeDoc.defaultView as any)?.checkMandatory291879000075425001;
        
        if (!checkMandatory) {
          console.error("Validation function not found");
          form.submit();
          return;
        }

        // Run the form's built-in validation
        const isValid = checkMandatory();
        
        if (isValid === false) {
          // If validation fails, let the form handle it naturally
          return;
        }

        const formData = new FormData(form);

        try {
          const response = await fetch(form.action, {
            method: "POST",
            body: formData,
          });

          if (response.ok) {
            setTimeout(() => {
              navigate(
                computePath(locale, defaultLocale, undefined, undefined, [
                  { locale: "it", value: "thankyou" },
                  { locale: "en", value: "thankyou" },
                  { locale: "es", value: "thankyou" },
                ])
              );
            }, 1500);
          } else {
            console.error("Form submission failed");
            // If submission fails, let the form handle the error
            form.submit();
          }
        } catch (error) {
          console.error("Error submitting form:", error);
          // If there's an error, let the form handle it
          form.submit();
        }
      });
    };

    // Add iframe load listener
    const iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.addEventListener("load", interceptForm);
    }

    // Cleanup
    return () => {
      if (iframe) {
        iframe.removeEventListener("load", interceptForm);
      }
    };
  }, [locale, defaultLocale]);

  return (
    <Container>
      <Title>{content.title}</Title>
      {content.textNode && (
        <Text
          dangerouslySetInnerHTML={{
            __html: content.textNode.childMarkdownRemark.html,
          }}
        />
      )}
      {content.formId && (
        <iframe
          src={`/zoho-forms/${content.formId}`}
          height={content.frameHeight}
          width="100%"
          frameBorder="0"
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100%;
    padding: 60px 20px;
    box-sizing: border-box;
  }
  select {
    margin-bottom: 30px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
`;

export default ZohoForm;